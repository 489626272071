
import Btn from '@/components/Btn'
export default {
  props: {
    html: {
      type: String,
      default: '',
    },
  },
  computed: {
    dynamicComponent() {
      return {
        template: '<div>' + dette.html + '</div>',
        props: [
          /* define props as needed */
        ],
      }
    },
  },
  render(createElement, context) {
    return createElement('div', [
      createElement(this.dynamicComponent, {
        components: {
          Btn,
        },
        props: {
          /* pass prop values as needed */
        },
      }),
    ])
  },
}
